import React from 'react';

import styled from 'styled-components';
import Task from '../Task';
import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';

import { Droppable } from 'react-beautiful-dnd';
import TaskDialog from '../TaskDialog';

import Countdown from '../Countdown';

const Container = styled.div`
  margin: 8px;
  border: 1px solid rgba(68, 71, 156, 0.718);
  border-radius: 24px;
  max-width: 31%;
  padding: 20px;
  display: inline-block;
  background-color: #1a2246;
`;

// color: #f0f0f0;

const TaskList = styled.div`
  padding: 8px;
`;

const AUTH_ENDPOINT = process.env.REACT_APP_TASKS_API;

const updateProjectList = async (updatedProjects, token) => {
  const projectsString = updatedProjects.join(',');
  const URL = AUTH_ENDPOINT + `project-list?projects=${projectsString}`;
  const HEADERS = {
    'Content-Type': 'application/json',
    Authorization: token,
  };

  const request = new Request(URL, {
    method: 'PUT',
    headers: HEADERS,
    timeout: 100000,
  });
  fetch(request);
};

function Column({ column, columnTasks }) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const { tasks, setTasks, projects, setProjects } =
    React.useContext(DataContext);
  const { token } = React.useContext(UserContext);

  const filteredTasks = columnTasks.filter((task) =>
    searchTerm
      .split(' ')
      .every((term) => task.content.toLowerCase().includes(term.toLowerCase()))
  );

  function deleteColumn(columnId, tasks) {
    const projectToDelete = tasks.columns[columnId].title;
    setTasks((prevTasks) => {
      const newColumns = { ...prevTasks.columns };
      delete newColumns[columnId];

      const newColumnOrder = prevTasks.columnOrder.filter(
        (id) => id !== columnId
      );

      return {
        ...prevTasks,
        columns: newColumns,
        columnOrder: newColumnOrder,
      };
    });
    const updatedProjects = projects.filter(
      (project) => project !== projectToDelete
    );
    setProjects(updatedProjects);
    updateProjectList(updatedProjects, token);
  }

  function shiftColumnLeft(columnId) {
    var newData = {};
    setTasks((prevTasks) => {
      const columnIndex = prevTasks.columnOrder.indexOf(columnId);
      const leftColumnId = prevTasks.columnOrder[columnIndex - 1];
      const newLeftColumn = {
        ...prevTasks.columns[columnId],
        id: leftColumnId,
      };
      const newRightColumn = {
        ...prevTasks.columns[leftColumnId],
        id: columnId,
      };
      const newColumns = {
        ...prevTasks.columns,
        [columnId]: newRightColumn,
        [leftColumnId]: newLeftColumn,
      };

      newData = {
        ...prevTasks,
        columns: newColumns,
      };
      return newData;
    });
    const updatedProjects = newData.columnOrder.map((columnId) => {
      return newData.columns[columnId].title;
    });
    setProjects(updatedProjects);
    updateProjectList(updatedProjects, token);
  }

  function getMidnightTonightET() {
    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0);
    const midnightET = new Date(
      midnight.toLocaleString('en-US', { timeZone: 'America/New_York' })
    );
    return midnightET.toISOString();
  }

  const tonightDeadline = getMidnightTonightET();

  const incompleteTaskCount = columnTasks.filter(
    (task) => task.status !== 'COMPLETE'
  ).length;

  return (
    <Container>
      <TitleContainer>
        <span>
          {`${column.title} (${incompleteTaskCount})`}
          {column.title === 'habits' && false ? (
            <Countdown deadline={tonightDeadline} />
          ) : (
            ''
          )}
        </span>
        <span>
          {column.id !== 'column-1' && (
            <button onClick={() => shiftColumnLeft(column.id, tasks)}>
              {leftIcon}
            </button>
          )}
          <button>
            <TaskDialog project={column.title} />
          </button>
          <button onClick={() => deleteColumn(column.id, tasks)}>
            {closeIcon}
          </button>
        </span>
      </TitleContainer>
      <SearchContainer>
        {/* TODO: replace placeholder text with magnifying glass icon */}
        <input
          type='text'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-search"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg>')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
            paddingLeft: '30px',
          }}
        />
      </SearchContainer>
      <Droppable droppableId={column.id}>
        {(provided, snapshot) => (
          <TaskList ref={provided.innerRef} {...provided.droppableProps}>
            {filteredTasks.map((task, index) => {
              return <Task key={task.id} task={task} index={index}></Task>;
            })}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
}

export default Column;

const leftIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-arrow-left'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='M16 12H8' />
    <path d='m12 8-4 4 4 4' />
  </svg>
);

const closeIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-x'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='m15 9-6 6' />
    <path d='m9 9 6 6' />
  </svg>
);

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-size: 1.17em;
  font-weight: bold;
  color: #f0f0f0;
`;

const SearchContainer = styled.span`
  margin-left: 10px;
  border: 1px solid black;
  padding: 5px;
`;
